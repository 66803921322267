import React from "react"
import { graphql } from "gatsby"
//import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"
// import gamesPerStat from "../../js/gamesPerStat"
// import Icon from '../../components/Utilities/Icon'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class AllAssists extends React.Component {

  constructor(props) {
    super(props)
    this.manageStatView = this.manageStatView.bind(this)
  }

  state = {
    statView: 'All Assists'
  };

  manageStatView = (view) => {
    this.setState({ statView: view });
  };

  render() {

    const pageTitle = "All Assists (Opta + Non-Opta)"

    // const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    // const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    // const subStat = (x, comp) => {
    //   return x === "m" ? (
    //     parseFloat((Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0])) / (Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.assists)[0])+Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.reboundGkAssist)[0])+Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.reboundPostAssist)[0])+Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.deflectedAssist)[0])+Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.penWonAssist)[0])+Number(mdata.filter(d => d.node.competition === comp).map(d => d.node.ownGoalAssist)[0]))).toFixed(1)
    //   ) : (
    //     parseFloat((Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0])) / (Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.assists)[0])+Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.reboundGkAssist)[0])+Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.reboundPostAssist)[0])+Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.deflectedAssist)[0])+Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.penWonAssist)[0])+Number(rdata.filter(d => d.node.competition === comp).map(d => d.node.ownGoalAssist)[0]))).toFixed(1)
    //   )
    // }

    const comps = [
      {
        "comp": "All Time Career",
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Club",
        "btnText": "Season Breakdown"
      },
      {
        "comp": "League",
        "btnText": "Season Breakdown"
      },
      {
        "comp": "Champions League",
        "btnText": "Season Breakdown"
      },
      {
        "comp": "Other Club Cups",
        "btnText": "Season Breakdown"
      },
      {
        "comp": "International",
        "btnText": "Yearly Breakdown"
      },
    ]


    return (

      <Layout>
        <SEO
          title={`All Types of Assists, including non-Opta Assists - Messi vs Ronaldo`}
          description={`Assists can vary by source - here you can find Messi and Ronaldo assist stats of every kind: Opta assists, deflected assists, rebound assists, own goal assists and penalties won.`}
          canonicalPath={`/detailed-stats/all-assists/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi vs Ronaldo Opta and non-Opta Assists</span></h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'All Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('All Assists'), 'active': this.state.statView === "All Assists" ? true : false },
            { 'name': 'Opta Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('Opta Assists'), 'active': this.state.statView === "Opta Assists" ? true : false },
            { 'name': 'Deflected Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('Deflected Assists'), 'active': this.state.statView === "Deflected Assists" ? true : false },
            { 'name': 'GK Rebounds', 'url': 'none', 'stateHandler': () => this.manageStatView('GK Rebounds'), 'active': this.state.statView === "GK Rebounds" ? true : false },
            { 'name': 'Post Rebounds', 'url': 'none', 'stateHandler': () => this.manageStatView('Post Rebounds'), 'active': this.state.statView === "Post Rebounds" ? true : false },
            { 'name': 'Own Goal Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('Own Goal Assists'), 'active': this.state.statView === "Own Goal Assists" ? true : false },
            { 'name': 'Pens Won', 'url': 'none', 'stateHandler': () => this.manageStatView('Pens Won'), 'active': this.state.statView === "Pens Won" ? true : false }
          ]}
          btnTextSize="text-xs lg:text-sm"
          btnPadding="px-3 py-1 lg:px-4 lg:py-2"
        />

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-4`}>

        {comps.map(d => (
          <DetailedStatBars
            key={d.comp}
            data={this.props.data}
            competition={d.comp}
            playerTitleHidden={`${d.comp} ${pageTitle}`}
            statType="All Assist Types"
            mainStatView={this.state.statView}
            btnText={d.btnText}
            statView={['Totals', 'Opta Assists', 'Deflected Assists', 'GK Rebounds', 'Post Rebounds', 'Own Goal Assists', 'Pens Won']}
          />
        ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo All Assists</h2>

          <InfoBar />

          <p>Below you can see a complete list of Messi and Ronaldo assists of all different types.</p>

          <p>Note that winning a penalty only counts as an assist if it is scored by a team mate - a player cannot assist themself.</p>

          <h3>Full List of Lionel Messi Career Assists</h3>

          <p>This list of Messi assists includes Opta assists, deflected pass assists, goalkeeper rebound assists, post rebound assists, own goal assists and penalties won.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
          <table className="w-full">
              <thead className="text-left">
                <tr>
                  <th>Date</th>
                  <th>Comp</th>
                  <th>Opponent</th>
                  <th>Assists</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.allSheetMessiMatchHistory.edges.map(d => (
                  <tr key={d.node.date+d.node.year}>
                    <td className="whitespace-no-wrap text-xs">{d.node.date}-{d.node.year}</td>
                    <td className="lg:whitespace-no-wrap text-xs">{d.node.competition}</td>
                    <td className="lg:whitespace-no-wrap text-xs">{d.node.opponent}</td>
                    <td className="whitespace-no-wrap text-xs lg:text-sm">
                      {Number(d.node.assists) > 0 ? <strong className='text-highlight px-1'>{d.node.assists} Opta <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.deflectedAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.deflectedAssist} deflected <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.reboundGkAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.reboundGkAssist} GK rebound <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.reboundPostAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.reboundPostAssist} post rebound <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.ownGoalAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.ownGoalAssist} own goal <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.penWonAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.penWonAssist} pen won <span className="sr-only">assist</span></strong> : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h3>Full List of Cristiano Ronaldo Career Assists</h3>

          <p>This list of Ronaldo assists includes Opta assists, deflected pass assists, goalkeeper rebound assists, post rebound assists, own goal assists and penalties won.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
          <table className="w-full">
              <thead className="text-left">
                <tr>
                  <th>Date</th>
                  <th>Comp</th>
                  <th>Opponent</th>
                  <th>Assists</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.allSheetRonaldoMatchHistory.edges.map(d => (
                  <tr key={d.node.date+d.node.year}>
                    <td className="whitespace-no-wrap text-xs">{d.node.date}-{d.node.year}</td>
                    <td className="lg:whitespace-no-wrap text-xs">{d.node.competition === "UEFA European Championships" ? 'UEFA Euros' : d.node.competition}</td>
                    <td className="lg:whitespace-no-wrap text-xs">{d.node.opponent}</td>
                    <td className="whitespace-no-wrap text-xs lg:text-sm">
                      {Number(d.node.assists) > 0 ? <strong className='text-highlight px-1'>{d.node.assists} Opta <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.deflectedAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.deflectedAssist} deflected <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.reboundGkAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.reboundGkAssist} GK rebound <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.reboundPostAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.reboundPostAssist} post rebound <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.ownGoalAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.ownGoalAssist} own goal <span className="sr-only">assist</span><br /></strong> : ''}
                      {Number(d.node.penWonAssist) > 0 ? <strong className='text-highlight px-1'>{d.node.penWonAssist} pen won <span className="sr-only">assist</span></strong> : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <p>If you have any questions about assist stats, contact us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          id
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          id
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }

    allSheetMessiMatchHistory(filter: {allAssists: {regex: "/[1-9]+/"}}) {
      edges {
        node {
          date
          year
          competition
          homeAway
          opponent
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          assists
          allAssists
        }
      }
    }
    allSheetRonaldoMatchHistory(filter: {allAssists: {regex: "/[1-9]+/"}}) {
      edges {
        node {
          date
          year
          competition
          homeAway
          opponent
          reboundGkAssist
          reboundPostAssist
          deflectedAssist
          penWonAssist
          ownGoalAssist
          assists
          allAssists
        }
      }
    }
  }
`

export default AllAssists
